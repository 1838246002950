<template>
  <div :class="$style.container">
    <vue-good-table
      :class="$style.table"
      :columns="columns"
      :rows="subscriptions"
      styleClass="vgt-table striped"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'createdAt'">
          {{ formatDate(props.row.createdAt) }}
        </div>
        <span v-else-if="props.column.field === 'product.name'">
          <router-link
            :to="`/sellers/subscriptions/product/${props.row.productId}`"
          >
            {{ props.row.product.name }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'requesites'">
          <router-link
            :to="`/sellers/dealers/client-requesites/${props.row.dealerAccountId}`"
          >
            Реквизиты
          </router-link>
        </span>
      </template>
    </vue-good-table>
    <Pagination
      v-if="totalPages > 1"
      @go-next="goNext()"
      @go-prev="goPrev()"
      :curPage="page"
      :lastPage="totalPages"
    />
    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import delivery from '@/delivery'
import { formatDate } from '@/helpers/index.js'
import Pagination from '@/components/atoms/Paginations.vue'
import Loader from '@/components/atoms/LoadingBar.vue'

export default {
  components: { Loader, Pagination },
  mounted() {
    this.getSubscriptions()
  },
  data() {
    return {
      columns: [
        {
          label: 'Дата Подписки',
          field: 'createdAt',
          sortable: false,
        },
        {
          label: 'Продукт',
          field: 'product.name',
          sortable: false,
        },
        {
          label: 'Дилер',
          field: 'dealerAccount.name',
          sortable: false,
        },
        {
          label: '',
          field: 'requesites',
          sortable: false,
        },
      ],
      subscriptions: [],
      page: 1,
      limit: 20,
      totalPages: 1,
      isLoading: false,
    }
  },

  methods: {
    async getSubscriptions() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.SubscriptionActions.getList({
          limit: this.limit,
          page: this.page,
          dealerAccountId: this.$route.params.id,
        })
      if (error) return
      this.subscriptions = value.data
      this.totalPages = Math.ceil(value.meta.count / this.limit)
      this.isLoading = false
    },
    async goNext() {
      if (this.page < this.totalPages) {
        this.page++
        await this.getSubscriptions()
      }
    },
    async goPrev() {
      if (this.page > 1) {
        this.page--
        await this.getSubscriptions()
      }
    },
    formatDate(date) {
      return formatDate(date)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
}
</style>
